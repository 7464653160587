// Traditional Chinese
export const locale = {
  lang: 'zh-Hant',
  data: {
    MENU: {
      TASK_DETAIL: '任務詳情',
      TASK_CONTENT: '任務內容',
      DASHBOARD: '儀表板',
      DASHBOARD_REP: '模組報表',
      DASHBOARD_TRA: '培訓儀表板',
      DASHBOARD_REG: '法規儀表板',
      DASHBOARD_RIS: '風險儀表板',
      DASHBOARD_INC: '事故儀表板',
      DASHBOARD_FOOD: '食安報表',
      DASHBOARD_ASSESSMENT: '評估問題分析',
      DASHBOARD_ISSUE: '隱患及5S分析',
      DASHBOARD_INSPECTION_CORRECTION: '检查整改分析',
      DASHBOARD_TRAINING: '培训与证件报表',
      DASHBOARD_BI_SMALL: '工商业微小项目',
      DASHBOARD_HC_SMALL: '医疗业微小项目',
      DASHBOARD_PERFORMANCE: '績效數據匯總表',
      WHITE_LIST: '白名单',
      FS_KPI: '餐饮服务 KPI',
      FMHC_KPI: '医疗业 KPI',
      FMBI_KPI: '工商业 KPI',
      ATP: 'ATP分析',
      DASHBOARD_HOMEPAGE: 'EHS绩效',
      DASHBOARD_CUSTOM_SAT: '医护满意度报表',
      KPI_EXEMPTION: 'KPI豁免',
      SYSTEM_SETUP: '系統設定',
      TAS110: '個人任務中心',
      TAS111: '任務管理',
      TAS112: '待認領任務',
      TRA100: '培訓與證件',
      TRA110: '培訓计划',
      TRA111: '培训日曆',
      TRA112: '部门培训',
      TRA113: '教師管理',
      TRA114: '班級排課',
      TRA115: '培訓日曆',
      TRA116: '豁免工具',
      TRA117: '员工紀錄',
      TRA118: '課程記錄',
      TRA119: '我的课程',
      TRA120: '證照管理',
      TRA121: '证件查询与录入',
      TRA122: '证件名称维护',
      TRA130: '培训记录',
      TRA131: '待完成培训',
      TRA132: '已完成培训',
      TRA133: '会议签到',
      TRA134: '我的课程',
      TRA135: '员工记录',
      TRA140: '题库管理',
      TRA141: '试题集管理',
      TRA142: '试卷管理',
      TRA143: '试题管理',
      TRA144: '培訓計劃',
      TRA150: '认证测评',
      TRA151: '认证测评成绩',
      TRA152: '认证测评管理',
      TRA153: '测评类型管理',
      RIS100: '風險評估',
      RIS110: '風險分析',
      RIS111: '風險矩陣',
      RIS112: '作業風險查詢',
      RIS120: '風險控制',
      RIS121: '風險評估排程',
      RIS130: '風險紀錄',
      RIS131: '風險評估任務',
      RIS132: '矯正整改',
      INC100: '事故管理',
      INC110: '事故處理',
      INC111: '事故通報',
      INC112: '事故查詢',
      INC113: '事故調查',
      INC114: '矯正整改',
      INC115: '工时列表',
      INC116: '工时详情',
      INC117: '受傷人員',
      INC120: '職災統計',
      INC121: '職災紀錄填報',
      INC122: '法定工作日紀錄',
      INC123: '職災統計報表',
      INC124: '職災紀錄填報表單',
      INC125: '消息中心',
      GR100: '法規鑑別',
      GR110: '法規資料庫',
      GR111: '法規總覽',
      GR112: '我的最愛',
      GR113: '公司適用清單',
      GR114: '上傳更新法規',
      GR120: '鑑別與整改',
      GR121: '查核活動',
      GR122: '鑑別任務',
      GR123: '矯正整改',
      SS100: '系統設定',
      SS110: '基本設定',
      SS111: '組織架構',
      SS112: '員工管理',
      SS113: '廠區管理',
      SS114: '员工群組',
      SS115: '公告管理',
      SS116: '角色管理',
      SS117: '崗位群組',
      SS120: '進階設定',
      SS121: '系統代碼',
      SS122: '角色管理',
      SS130: '表格管理',
      SS131: '題庫',
      SS132: '表格',
      SS133: '整改措施',
      SS140: '空间管理',
      SS141: '空间管理',
      SS142: '空间类型管理',
      SS143: '空间群组管理',
      SS144: '满意度科室空间管理',
      SS145: '醫護滿意度調查月度匯總表',
      INS100: '檢查管理',
      INS110: '檢查活動',
      INS111: '巡檢回報',
      INS112: '檢查排程',
      INS113: '立即檢查',
      INS120: '檢查結果',
      INS121: '檢查查询',
      INS122: '缺失發現',
      INS123: '矯正改善',
      INS124: '隐患整改查询',
      INS125: '隐患及5S上报查询',
      CHA100:'變更管理',
      CHA110:'項目初始化',
      CHA120:'日常變更管理',
      CHA130:'變更查詢',
      CHA140:'整改措施',
      NM100: '通知管理',
      NM111: '消息中心',
      MEDICAL_SERVICE: '医疗服务',
      ENV100: '环境服务',
      ENV110: '记录查询',
      ENV111: '空间检查查询',
      ENV112: '岗位评估查询',
      ENV113: '患者满意度查询',
      ENV114: '空间计划检查',
      ENV115: '空间查询报表',
      ENV116: '客户质量檢查查詢',
      DLV100: '運送陪護服務',
      DLV110: '记录查询',
      // DLV111: '空间检查查询',
      DLV111: '運送陪護服務檢查查詢',
      DLV112: '岗位评估查询',
      DLV113: '患者满意度查询',
      DLV114: '空间计划检查',
      EQP100: '设施服务',
      EQP110: '记录查询',
      EQP111: '岗位评估查询',
      EQP112: '设备检查查询',
      // EQP113: '空间检查查询',
      EQP113: '设施运维服务检查查询',
      EQP114: '患者满意度查询',
      EQP115: '空间计划检查',
      SQR100: '安保服务',
      SQR110: '记录查询',
      SQR111: '岗位评估查询',
      SQR112: '设备检查查询',
      // SQR113: '空间检查查询',
      SQR113: '空间安全检查查询',
      SQR114: '患者满意度查询',
      SQR115: '空间计划检查',
      SMR116: '医疗业报表',
      SMR117: '医疗业专业培训报表',
      SMR118: '患者滿意度調查月度彙總表',
      SMR119: '崗位評估報表',
      QPE100: 'ASE与QPE',
      QPE110: 'QPE管理',
      QPE111: 'QPE上报',
      QPE112: 'QPE查询',
      QPE113: '计划检查',
      QPE114: 'QPE整改查询',
      QPE120: '项目自评管理',
      QPE121: '项目自评查询',
      QPE122: '项目自评整改查询',
      QPE130: 'ASE管理',
      QPE131: 'ASE上报',
      QPE132: 'ASE查询',
      QPE133: 'ASE整改查询',
      QPE134: 'ASE上傳資料',
      DS100: '医护满意度',
      DS110: '医护满意度管理',
      DS111: '医护满意度查询',
      DS112: '二维码',
      DOC100: '合規文件管理',
      DOC110: '合規文件管理',
      DOC111: '合規文件管理',
      DOC112: '合規文件清單',
      DOC113: '合規文件分類',
      DOC114: '項目報告',
      SCS110: 'EHS積分',
      SCS111: '積分規則',
      SCS112: '積分查詢',
    },
    GENERAL: {
      ACTIONS: {
        CHANGE_PASSWORD: '修改密碼',
        LOGOUT: '登出',
        OK: '確認',
        CANCEL: '取消',
        CLOSE: '關閉',
        LEAVE: '離開',
        SUBMIT: '提交',
        RESET: '重設',
        SEARCH: '搜尋',
        VIEW: '檢視',
        ADD: '新增',
        EDIT: '編輯',
        EXPORT_ALL: '全部匯出',
        EXPORT: '匯出',
        DELETE: '刪除',
        UPDATE: '更新',
        SAVE: '儲存',
        DO_NOT_SAVE: '不儲存',
        SAVE_CONFIRM: '儲存並確認',
        SAVE_LEAVE: '儲存並離開',
        SAVE_SUBMIT: '儲存並提交',
        BACK: '返回',
        PREVIOUS: '上一步',
        NEXT: '下一步',
        NO: '否',
        YES: '是',
        CLEAR_SELECTION: '無',
        BACK_TO_LIST: '回列表頁',
        PUBLISH: '發布',
        CHOOSE: '選擇',
        CLEAR: '清除',
        ADD_NEW_VERSION: '發布',
        ADVANCED_SEARCH_SHOW: '顯示進階搜尋',
        ADVANCED_SEARCH_HIDE: '隱藏進階搜尋',
        SUBMIT_PREVIEW: '提交预览',
        ASSIGN: '轉派',
        CLAIM: '認領',
        EXPORT_EXCEL: '导出Excel',
        EXPORT_PDF: '导出pdf',
        DRAFT: '保存草稿',
        PRINT: '打印',
        CLOSE_CASE: '结案',
        REVOKE: '撤销',
        REJECT: '駁回',
        SHUTDOWN: '终止',
        END: '结束',
        CALCULATE: '回顾'
      },
      NO_ATTACHMENTS: '请添加附件！',
      FORM_REGION: '大項',
      FORM_SECTION: '分區',
      POSITIVE: '是',
      NEGATIVE: '否',
      TITLE: {
        DELETE: '確認刪除',
        LEAVE: '確認離開?',
        SURE: '確認清除回報'
      },
      MSG: {
        DELETE: '刪除後紀錄將會清空且無法復原，確定要刪除？',
        LEAVE: '您尚未儲存此筆資料，請問您確定離開？',
        CONFIRM_LEAVE: '当前页面可能存在未保存内容，您确定离开吗？',
        SURE: '確認清除事故回報嗎？',
        UNFILLED_FIELD: '無法儲存，有尚未填寫的欄位。',
        FIELD_EMPTY: '必填項未填寫，請補全',
        CREATE_SUCCESS: '添加成功',
        UPDATE_SUCCESS: '更新成功',
        DELETE_SUCCESS: '删除成功',
        SAVE_SUCCESS: '保存成功',
        SUBMIT_SUCCESS: '提交成功',
        PUBLISH_SUCCESS: '发布成功',
        REVOKE_SUCCESS: '撤销成功',
        CLOSE_SUCCESS: '终止成功',
      },
      NO_RESULT: '沒有結果',
      NONE: '無',
      PLACEHOLDER: {
        SEARCH: '輸入以搜尋',
        SELECT: '請選擇',
        CLARIFY: '請說明',
        INPUT: '请输入'
      },
      SELECT_REQUIRED: '請選擇一項',
    },
    LABEL: {
      CHECKBOX_SELECTED: '已選擇'
    },
    DX: {
      NO_DATA_TEXT: '無資料',
      NO_DATA_TEXT_MEDICAL:'無逾期未完成數據',
      NO_DATA_TEXT_EMP:'無待培訓員工數據',
      PAGER: {
        INFOTEXT: '第 {0} 頁，共 {1} 頁 (共 {2} 項)'
      },
      UPLOADER: {
        SELECT_BTN_TEXT: '選擇檔案',
        LABEL_TEXT: '或拖曳檔案至此',
        READY_MSG: '準備上傳',
        MAX_FILE_SIZE: '檔案大小限制',
        INVALID_MAX_FILE_SIZE_MSG: '檔案過大',
        IGNORE_OVERLARGE_FILE_HINT: '過大的檔案將不會上傳',
        ONLY_PIC: '只能上傳圖片格式文件',
        RESET: '清除已選檔案',
        DELETE: '刪除檔案',
        CONFIRM_DELETE: '您確定要刪除此檔案嗎？',
        FAILED_TO_UPLOAD: '未能成功上傳',
        IMPORT: '导入',
        PICTURE_HINT: '请上传jpg, jpeg, gif, png格式的图片,建议图片大小不超过2M',
        FILE_INVALID: '上传的文件不符合要求，请重新选择',
        INVALID_FILE_EXTENSION_MSG: '文件格式不符合要求',
      }
    },
    MAT: {
      PAGINATOR: {
        ITEMS_PER_PAGE: '每頁',
        FIRST: '第一頁',
        LAST: '最後一頁',
        PREVIOUS: '上一頁',
        NEXT: '下一頁',
      },
    },
    HTTP_ERROR_MSG: {
      '401': '认证失效，请重新登录',
      '404': '系統下線或維護中，請稍候再試',
      '413': '上傳檔案過大，請嘗試較小檔案',
      '504': '因導出數據量較大，導出數據無法及時返回，導出完成後消息中心會有提醒，請耐心等待。'
    }
  }
};
